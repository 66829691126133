<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getDeptsNow()">
          <el-row :gutter="24">
            <el-col>
              <el-form-item label="部门名称：">
                <el-input size="small" v-model.trim="searchForm.dept_name" clearable placeholder="请填写部门名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="时间范围：">
                <div class="block">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getDeptsNow()" class="vg_ml_16">查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="mBox()"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            :data="tableData"
            v-loading="loadFlag"
            @selection-change="handleSelectionChange"
            @row-dblclick="dbClickJp"
            border
            class="vg_cursor"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column prop="dept_id" label="id" width="50" align="center"></el-table-column>
            <el-table-column label="部门名称" :show-overflow-tooltip="true" prop="dept_name" />
            <el-table-column label="所含小组" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.dept_team_names">{{ scope.row.dept_team_names }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="部门简介" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.dept_brief">{{ scope.row.dept_brief }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <!--          <el-table-column label="录入人"  :show-overflow-tooltip='true' prop="stff_name"/>-->
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" />
            <!--          <el-table-column label="在批状态" :show-overflow-tooltip='true' align="center">-->
            <!--            <el-tag :type="helper.getStatusName(tableData.status).type">{{helper.getStatusName(tableData.status).name}}</el-tag>-->
            <!--          </el-table-column>-->
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { deptAPI } from '@api/modules/department';
import pubPagination from '@/components/common/pubPagination';
import publicTips from '@/views/component/publicTips';
export default {
  name: 'DeptList',
  components: {
    pubPagination,
    publicTips
  },
  data() {
    return {
      searchForm: {
        dept_name: '',
        timeValue: {
          startTime: null,
          endTime: null
        }
      },
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      loadFlag: true
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/dept_add' || from.path === '/dept_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getDepts();
    },

    // 获取部门信息
    getDepts() {
      this.getTime();
      get(deptAPI.getDepts, {
        dept_name: this.searchForm.dept_name,
        start_time: this.searchForm.timeValue.startTime,
        end_time: this.searchForm.timeValue.endTime,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.btn = res.data.data.btn;
            this.totalPage = res.data.data.total;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 时间转换
    formatDate(row) {
      if (row.create_time) {
        return this.helper.toTime(row.create_time);
      }
      if (row.update_time) {
        return this.helper.toTime(row.update_time);
      }
    },
    // 获取时间开始时间和结束时间戳
    getTime() {
      if (this.searchForm.timeValue && this.searchForm.timeValue.length === 2) {
        this.searchForm.timeValue.startTime = this.searchForm.timeValue[0];
        this.searchForm.timeValue.endTime = this.searchForm.timeValue[1];
        this.searchForm.timeValue.startTime = Number(new Date(this.searchForm.timeValue.startTime).getTime() / 1000);
        this.searchForm.timeValue.endTime = Number(new Date(this.searchForm.timeValue.endTime).getTime() / 1000);
      } else {
        this.searchForm.timeValue = [];
      }
    },
    // 查询方法
    getDeptsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getDepts();
    },
    // 分页
    changePageSearch(val) {
      this.currentPage = val;
      this.getDepts();
    },
    // 多选获取部门信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 新增
    addRow() {
      this.jump('/dept_add', { perm_id: this.$route.query.perm_id });
    },
    //删除提示
    mBox() {
      if (this.multiSelection.length > 0) {
        this.$confirm('确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.delRow();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
            this.$refs.multiTable.clearSelection();
          });
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.dept_id);
      });
      post(deptAPI.deletDeptByIds, { dept_id_list: ids })
        .then(res => {
          if (res.data.code === 0) {
            let mg = res.data.msg;
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.getDeptsNow();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.getDeptsNow();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.getDeptsNow();
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/dept_edit', { perm_id: this.$route.query.perm_id, form_id: row.dept_id });
    }
  }
};
</script>

<style scoped lang="scss"></style>
