import topic from '../topic';
export const deptAPI = {
  getDepts: topic.deptTopic + '/get_depts',
  getAllDeptsV1: topic.deptTopic + '/get_all_depts_v1',
  getAllDeptTeams: topic.deptTopic + '/get_all_dept_teams_v1',
  getDeptById: topic.deptTopic + '/get_dept_by_id',
  addDept: topic.deptTopic + '/add_dept',
  editDept: topic.deptTopic + '/edit_dept',
  deletDeptByIds: topic.deptTopic + '/delete_dept_by_ids',
  getTeamsByDeptId: topic.deptTopic + '/get_dept_teams_by_dept_id'
};
