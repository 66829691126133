const pathRewrite = '/api';
let prodUrl = '';
if (process.env.NODE_ENV === 'production') {
  prodUrl = '/dyj';
}

let topic = {
  dataTopic: pathRewrite + '/data', // data公共类api
  imgeTopic: prodUrl + pathRewrite + '/imge', // 图片上传
  cpttTopic: pathRewrite + '/cptt', // 公司抬头信息管理
  deptTopic: pathRewrite + '/dept', // 部门信息管理
  stffTopic: pathRewrite + '/stff', // 员工信息管理
  roleTopic: pathRewrite + '/role', // 角色管理
  permTopic: pathRewrite + '/perm', // 权限管理
  userTopic: pathRewrite + '/user', // 用户管理
  apprTopic: pathRewrite + '/appr', // 审批管理
  leavTopic: pathRewrite + '/leav', // 请假管理
  optnTopic: pathRewrite + '/optn', // 数据中心
  custTopic: pathRewrite + '/cust', // 客户信息
  suppTopic: pathRewrite + '/supp', // 供应商信息
  mtrlTopic: pathRewrite + '/mtrl', // 物料信息
  mdrlTopic: pathRewrite + '/mdrl', // 模具物料信息
  prodTopic: pathRewrite + '/prod', // 产品信息
  docuTopic: pathRewrite + '/docu', // 相关文档
  requTopic: pathRewrite + '/requ', // 委托打样单
  requToPC: pathRewrite + '/pc', // 同步核价单到蒲橙
  smplTopic: pathRewrite + '/smpl', // 样品总控表
  sourTopic: pathRewrite + '/sour', // 采购申请表
  qrcdTopic: pathRewrite + '/qrcd', // 二维码
  mstfTopic: pathRewrite + '/mstf', // 物料出入库明细
  mstkTopic: pathRewrite + '/mstk', // 总库存表
  procTopic: pathRewrite + '/proc', // 运营
  rfidTopic: pathRewrite + '/rfid', // 开卡
  spocTopic: pathRewrite + '/spoc', // 提交后修改工序
  openTopic: pathRewrite + '/open', // 同步mes
  colrTopic: pathRewrite + '/color', // 同步mes
  qutaTopic: pathRewrite + '/quta', // 样品报价单
  settTopic: pathRewrite + '/sett', // 参数信息
  pdfTopic: pathRewrite + '/pdf',
  statTopic: pathRewrite + '/dataStatistics', //数据统计
  npsmTopic: pathRewrite + '/npsm', //非毛绒类样品
  mdinTopic: pathRewrite + '/mdin', //模具信息库
  mdopTopic: pathRewrite + '/mdnc', //模具开模通知单
  mqcdTopic: pathRewrite + '/mqcd', //模具二维码信息
  mdtkTopic: pathRewrite + '/mdtk', //模具总库存
  mdtfTopic: pathRewrite + '/mdtf', //模具出入库
  mmplTopic: pathRewrite + '/mmpl', //模具总控表
  muopTopic: pathRewrite + '/muop', //开卡运营
  mupcTopic: pathRewrite + '/mupc', //工序配置
  moudTopic: pathRewrite + '/moud' //模具采购申请单
};
export default topic;
